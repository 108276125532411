import React from "react"

import Layout from "components/Layout"

import { Hero, Content } from "page_components/raport-new-map"

const NewMap = ({ location }) => {
  const title = "Raport nowa mapa Kielc"

  return (
    <Layout location={location} seo={{ title: title }}>
      <Hero />
      <Content />
    </Layout>
  )
}

export default NewMap
