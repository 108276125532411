import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"
import scrollToElement from "scroll-to-element"

const Hero = () => {
  return (
    <section className="rnm-hero">
      <div className="rnm-hero__wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="rnm-hero__content d-flex flex-column">
                <div className="rnm-hero__logo d-flex align-items-center">
                  <img
                    src={
                      require("assets/images/raport-new-map/logo-sky-trust.svg")
                        .default
                    }
                    alt="Sky Trust"
                  />
                </div>
                <h1>
                  Wydaliśmy
                  <br /> raport <br />
                  “Nowa <br /> Mapa Kielc”
                </h1>
                <div className="rnm-hero__actions d-flex align-items-center">
                  <a
                    className="rnm-button"
                    href={withPrefix("/raport-nowa-mapa-kielc.pdf")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pobierz raport
                  </a>
                  <button
                    className="rnm-link"
                    onClick={() =>
                      scrollToElement("#content", {
                        duration: 500,
                      })
                    }
                  >
                    Dowiedz się więcej
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rnm-hero__map">
          <img
            src={require("assets/images/raport-new-map/map.jpg").default}
            alt="Mapa"
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
