import "./styles.scss"

import React, { useEffect, useRef, useState } from "react"
import { withPrefix } from "gatsby"

const Content = () => {
  const magazineRef = useRef(null)
  const resizeDebounce = useRef(null)

  const [isInit, setIsInit] = useState(false)
  const [magazineHeight, setMagazineHeight] = useState(null)

  useEffect(() => {
    const magazine = magazineRef.current

    const getMagazineHeight = () => {
      if (magazine) {
        clearTimeout(resizeDebounce.current)

        resizeDebounce.current = setTimeout(() => {
          const height = magazine.clientHeight
          const computedStyle = window.getComputedStyle(magazine)
          const top = parseInt(computedStyle.getPropertyValue("top"))

          setMagazineHeight(height + top)
        }, 100)
      }
    }

    getMagazineHeight()

    window.addEventListener("resize", getMagazineHeight)

    return () => window.removeEventListener("resize", getMagazineHeight)
  }, [])

  useEffect(() => {
    if (magazineHeight !== null) setIsInit(true)
  }, [magazineHeight])

  return (
    <section
      className={`rnm-content${isInit ? " rnm-content--init" : ""}`}
      style={{
        "--magazine-height": magazineHeight ? `${magazineHeight}px` : "auto",
      }}
    >
      <div className="rnm-content__magazine" ref={magazineRef}>
        <img
          src={require("assets/images/raport-new-map/magazine.png").default}
          alt="Magazyn"
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="rnm-content__intro d-flex flex-column justify-content-end">
              <div>
                <h2>
                  „Nowa Mapa Kielc” – zdefiniowaliśmy nowe symbole miasta
                  <span id="content" className="rnm-anchor" />
                </h2>
                <p>
                  Kielce to miasto dynamicznych zmian i ambitnych inwestycji. W
                  naszym raporcie „Nowa Mapa Kielc” pokazujemy 15 kluczowych
                  miejsc, inicjatyw i projektów, które definiują jego
                  przyszłość.
                </p>
                <p>
                  Jak w ostatnich latach zmieniły się Kielce? Jakie nowe symbole
                  kształtują ich tożsamość? Jakie inwestycje przyciągają biznes
                  i mieszkańców?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 col-lg-6 d-flex flex-column">
            <div className="rnm-content__image d-flex flex-column flex-grow-1">
              <div className="flex-grow-1">
                <img
                  src={
                    require("assets/images/raport-new-map/content.jpg").default
                  }
                  alt="Nowa mapa Kielc"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1 d-flex flex-column justify-content-center">
            <div>
              <div className="rnm-content__description">
                <p>
                  Współczesne Kielce to ośrodek pełen ambicji, intensywnych
                  zmian i nowych symboli. Nasz flagowy projekt, Sky Trust, który
                  już teraz staje się nową wizytówką miasta, skłonił nas do
                  szerszej refleksji nad jego transformacją –{" "}
                  <em>mówi Patryk Barucha</em>, Prezes Zarządu Trust Investment.
                </p>
                <p>
                  W raporcie, obok naszej inwestycji, wskazujemy także Targi
                  Kielce, Teatr im. Stefana Żeromskiego, Kielecki Park
                  Technologiczny i inne kluczowe miejsca, które wyznaczają nową
                  jakość życia w stolicy województwa świętokrzyskiego.
                </p>
                <p>
                  Pobierz raport, odkryj nowoczesne Kielce i zobacz, jak zmienia
                  się miasto!
                  <br />
                  Zapraszamy do lektury!
                  <br />
                  Zespół Trust Investment!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rnm-content__cta d-flex justify-content-center">
          <a
            className="rnm-button"
            href={withPrefix("/raport-nowa-mapa-kielc.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Pobierz raport
          </a>
        </div>
      </div>
    </section>
  )
}

export default Content
